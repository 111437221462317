<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						Got a question? We've got answers. If you can't find an answer to your question below
						<router-link to="/contact">
							contact Sydney Kids Neuropsychology
						</router-link>&nbsp;and we'll be in touch.
					</p>
					<div>
						<h3>Neuropsychology</h3>
						<accordion :faq="faq.neuropsychology" />
					</div>
					<div>
						<h3>Neuropsychological Assessments</h3>
						<accordion :faq="faq.assessments" />
					</div>
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Accordion from '@/components/accordion/Accordion';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'Faqs',
	components: {
		Accordion,
		Breadcrumbs,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			faq: {
				neuropsychology: [
					{
						question: 'What information will I need to bring to the assessment?',
						answer: `
							<p>Please provide copies of any previous psychological or neuropsychological assessments that your child has had, as well as any recent school report cards or copies of Individual Educational Plan (IEP). You may also be asked to complete questionnaires detailing your child’s medical history, early development, behaviour and school history.</p>
						`
					},
					{
						question: 'Is a referral required?',
						answer: `
							<p>A referral from a medical professional is not necessary to obtain an appointment with a neuropsychologist, although many children and young people will be referred by a doctor. Referrals are also accepted from allied health professionals, teachers or parents.</p>
							<p>For an assessment of Autism for a child who is under 13 years old a referral from a paediatrician or psychiatrist is required to access medicare rebates.</p>
						`
					},
					{
						question: 'How does a Neuropsychologist differ from a Clinical Psychologist or School Counsellor?',
						answer: `
							<p>Paediatric neuropsychologists and clinical or school psychologists/counsellor often use some of the same tests, however, Neuropsychologist differs from other psychologists in what they do with, whereas neuropsychologists focus on understanding why a child is having problems. For example the school psychologist/counsellor may be interested in the score that the child obtains. The neuropsychologist is interested in how the child obtains a specific test score as well as in the pattern of scores across different tests. Skills are broken down into component parts, attempting to define a pattern of strengths and weaknesses. For example, a child may have difficulty following a direction because he/she did not pay attention to the direction, did not understand the direction, or did not remember the direction. The paediatric neuropsychologist works to understand where the child is having trouble and why.</p>
							<p>The neuropsychologist may look at a broader range of skills, those of which are not usually tested by the clinical or school psychologist such as intelligence, academic skills, attention and concentration, learning and memory, processing speed, visual spatial perception, language skills, visual motor and fine motor skills, sensory perception, executive functioning (such as planning, organization, initiating and inhibiting behaviors) and emotional functioning.</p>
							<p>A neuropsychological assessment may include tests of the child’s ability to:</p>
							<ul>
								<li>File information in memory and to retrieve this information once learned (memory skills).</li>
								<li>Interpret what the child hears, sees, and touches (auditory, visual and tactual processing skills).</li>
								<li>Coordinate what the child sees with his/her hand movements for activities such as drawing, writing, and manipulating objects (visual motor and fine motor control).</li>
								<li>Understand what people say to the child and put words together to share information, thoughts and feelings with others (receptive and expressive language).</li>
								<li>Initiate work, turning directions into a plan of action for doing the task, modifying the plan if it doesn’t work and keeping track of materials needed to do the task (organisational skills).</li>
								<li>Pay attention, focusing attention on what is important for doing a task, and remaining attentive until the task is completed (attention skills).</li>
							</ul>
							<p>The neuropsychologist interprets the pattern of results in the context of the child’s developmental stage, their current setting and the medical history. Understanding a child’s cognitive strengths and weaknesses helps to understand potential areas of future difficulty and enables the development of specific intervention plans.</p>
						`
					}
				],
				assessments: [
					{
						question: 'How do I prepare my child for a neuropsychological assessment?',
						answer: `
							<p>The assessment process is structured to be a fun, relaxed, and non-threatening experience for the child. Time will be taken to ensure that children feel relaxed and comfortable in the presence of the neuropsychologist.</p>
							<p>The neuropsychological assessment will involve the child completing of a wide variety of activities, most of which are completed sitting at a table. Some activities involve listening and talking, while others involve looking at things, building things and drawing. For younger children, you can describe neuropsychological assessment as playing games involving listening, talking and remembering. Let the child know that the neuropsychologist will have toys like blocks and puzzles for them to play with. For older school aged children, parents can inform their child that the activities will help to understand how they learn so that school work does not have to be so difficult. Parents are encouraged to discuss the assessment in a non-threatening manner that does not leave the child feeling anxious. Avoid using words such as ‘test’, ‘pass’ and ‘fail’, instead using ‘activities’ or ‘games’ and telling your child that they just need to try their best.  Ensuring your child has slept well and has eaten prior to the assessment will help. Short breaks will be provided when required therefore please bring any snacks or water for your child as required. Younger children may wish to bring a security object along to the appointment. Make the assessment day a special day for your child by leaving brothers and/or sisters at home where possible. The assessment is non-invasive and does not involve pain, needles or electrodes.</p>
						`
					},
					{
						question: 'When do I know if my child needs an assessment of their academic abilities?',
						answer: `
							<p>Some parents may feel that their child is not learning at the rate of their peers, may engage in disruptive behaviour or may have difficulty with specific academic areas such as reading or maths. If this is the case, you may want to consider and assessment of your child’s academic abilities.</p>
							<p>You may also believe your child has superior academic abilities and it may be useful to identify these areas to support their growth and development of their talents.</p>
						`
					},
					{
						question: 'When do I know if my child needs an assessment of their attention and concentration?',
						answer: `
							<p>If you notice that your child demonstrates some of the below behaviours, you may consider that they need an assessment of their attention and concentration:</p>
							<ul>
								<li>Failure to give close attention to detail or careless mistakes.</li>
								<li>Difficulties keeping focused on tasks.</li>
								<li>Does not seem to listen when spoken to directly.</li>
								<li>Does not follow through on instructions and fails to finish tasks.</li>
								<li>Difficulties in organising tasks or activities.</li>
								<li>Avoidance of tasks that require sustained mental effort.</li>
								<li>Losing things necessary for the task or activities.</li>
								<li>Distraction by external events or unrelated thoughts.</li>
								<li>Forgetfulness of daily activities.</li>
							</ul>
						`
					}
				]
			},
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-01-small',
				imgLarge: 'hero-01-large'
			},
			meta: {
				description: 'Sydney Kids Neuropsychological assessments questions and answers.',
				title: 'Frequently Asked Questions'
			}
		};
	}
};
</script>
